.projects {
  margin: 20px 0 50px 0;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  margin-top: 20px;

  h2 {
    font-size: 40px;
    font-family: $roboto;
    margin: 0 0 10px 0;
    border-bottom: 1px solid rgb(0, 135, 255);
    width: 400px;
    text-align: center;
    padding-bottom: 10px;
  }

  .content {
    display: flex;

    .image {
      width: 550px;
    }

    .desc {
      width: 350px;

      li {
        padding: 5px;
      }

      a {
        text-decoration: none;
        color: rgb(0, 135, 255);
      }
    }
  }

  .image img {
    width: 550px;
    height: auto;
  }
}

.current {
  display: flex;
  justify-content: space-evenly;
  width: 250px;

  .ticker-light {
    background-color: grey;
    height: 15px;
    width: 15px;
    border-radius: 10px;
    animation: ticker 1s linear alternate infinite;
    position: relative;
    top: 3px;
  }

  @keyframes ticker {
    0% {
      background-color: grey;
    }
    100% {
      background-color: red;
    }
  }

  h4 {
    margin: 0 0 5px 0;
    text-decoration: underline;
    color: rgb(0, 135, 255);
  }

  ul {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .current {
    position: relative;
    left: -70px;
    width: 220px;

    h4 {
      font-size: 16px;
    }

    li {
      font-size: 14px;
    }
  }

  .project {
    width: 300px;
    margin-top: 10px;

    h2 {
      width: 300px;
      font-size: 28px;
    }
  }

  .content {
    flex-direction: column;
    align-items: center;

    .image {
      display: flex;
      justify-content: center;

      img {
        width: 300px;
      }

      #onedayin-image,
      #battleship-image {
        position: relative;
        top: -260px;
      }
    }

    #onedayin-text {
      position: relative;
      top: 160px;
    }

    #battleship-text {
      position: relative;
      top: 190px;
    }

    li {
      font-size: 14px;
      padding: 2px;
    }
  }
}
