.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h4 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
  }

  .details {
    display: flex;
    justify-content: space-evenly;
    width: 500px;
  }

  .phone,
  .email {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    i {
      padding-right: 10px;
      font-size: 20px;
    }
  }

  .icons {
    display: flex;
    justify-content: space-evenly;
    width: 400px;

    i {
      font-size: 40px;
    }

    a {
      color: white;
    }

    a:hover {
      color: rgb(0, 135, 255);
    }
  }
}

@media screen and (max-width: 600px) {
  .contact {
    width: 300px;
    margin: 0 auto;

    h4 {
      text-align: center;
      font-size: 16px;
    }

    .details {
      flex-direction: column;
      margin: 0 auto;
      width: 300px;
      align-items: center;
    }

    .phone {
      margin-bottom: 0;
    }
  }
}
