.home {
  margin: 0 auto;
  margin-top: 10px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .quotes {
    width: 900px;
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .quote-2 {
      align-self: flex-end;
      width: 370px;
    }

    h4 {
      font-size: 16px;
      margin: 8px 0;
      font-style: italic;
      font-weight: 400;
    }
  }

  h3 {
    font-size: 28px;
    margin: 10px 0;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }

  .intro {
    font-size: 20px;
    border-bottom: 1px solid rgb(0, 135, 255);
    width: 300px;
    padding-bottom: 5px;
  }

  p {
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .home {
    width: 300px;
    margin-top: 10px;

    .quotes {
      width: 300px;
      align-items: center;

      h4 {
        width: 250px;
        font-size: 14px;
      }

      .quote-2 {
        align-self: center;
        width: 280px;
      }

      .quote-1 {
        width: 280px;
      }
    }

    h3 {
      font-size: 20px;
      text-align: center;
    }

    .intro {
      font-size: 18px;
    }

    h4 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
}
