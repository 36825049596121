.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 200px;
  margin-top: 10px;
}

h1 {
  font-family: $roboto;
  margin: 0;
}

.const {
  color: rgb(255, 153, 0);
}

.name {
  color: rgb(176, 15, 149);
}

.result,
.typing {
  color: aqua;
}

.typing {
  animation: flicker 1s linear infinite;
}

@keyframes flicker {
  0% {
    opacity: 100%;
  }
  49% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
  99% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 14px;
    margin-top: 5px;
  }

  img {
    width: 150px;
  }
}
