.skills {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 24px;
    text-align: center;
    width: 800px;
  }

  h3 {
    border-bottom: 1px solid white;
    width: 400px;
    text-align: center;
    padding-bottom: 10px;
  }

  .skill-list {
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;

      .logo {
        background-color: rgb(243, 243, 243);
        height: 120px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100px;
          width: auto;
          max-width: 300px;
          margin: 0;
        }
      }

      h4 {
        font-size: 20px;
        margin: 5px 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .skills {
    h2 {
      font-size: 16px;
      width: 300px;
    }

    h3 {
      font-size: 14px;
      width: 300px;
    }

    .skill-list {
      width: 300px;

      .skill {
        h4 {
          font-size: 12px;
        }

        .logo {
          width: 100px;
          height: 60px;

          img {
            height: 50px;
            width: auto;
            max-width: 80px;
          }
        }
      }
    }
  }
}
