.about-page > div {
  display: flex;
  width: 900px;
  justify-content: center;
  margin-bottom: 40px;
  align-items: flex-start;
}

.about-page {
  margin: 50px 0;
}

.first-section:hover,
.third-section:hover,
.fifth-section:hover {
  animation: shiftRight 20s linear infinite;
}

.second-section:hover,
.fourth-section:hover,
.sixth-section:hover {
  animation: shiftLeft 20s linear infinite;
}

@keyframes shiftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shiftLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

.picture-section {
  width: 400px;
  display: flex;
  align-items: flex-start;

  img {
    width: 400px;
    height: auto;
    margin: 0;
  }
}

.text-section {
  width: 500px;
  height: 266.667px;
  font-size: 18px;
  background-color: rgb(0, 136, 255);
  padding: 10px;

  h4 {
    margin: 5px 0 10px 0;
  }

  p {
    margin: 0;
  }
}

.right-triangle {
  width: 0;
  height: 120px;
  border-top: 134px solid transparent;
  border-bottom: 134px solid transparent;
  border-left: 100px solid rgb(0, 136, 255);
}

.left-triangle {
  width: 0;
  height: 120px;
  border-top: 134px solid transparent;
  border-bottom: 134px solid transparent;
  border-right: 100px solid rgb(0, 136, 255);
}

.first-section img,
.third-section img,
.fifth-section img {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-left: 1px solid transparent;
}

.second-section img,
.fourth-section img,
.sixth-section img {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right: 1px solid transparent;
}

@media screen and (max-width: 600px) {
  .about-page {
    margin-top: 20px;
  }
  .about-page > div {
    width: 300px;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .about-page > div:hover {
    animation: none;
  }

  .left-triangle,
  .right-triangle {
    display: none;
  }

  .picture-section img {
    width: 300px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .text-section {
    width: 300px;
    height: auto;

    p {
      font-size: 14px;
    }
  }

  .second-section {
    img {
      position: relative;
      top: -160px;
    }

    .text-section {
      position: relative;
      top: 198px;
    }
  }

  .fourth-section {
    img {
      position: relative;
      top: -229px;
    }

    .text-section {
      position: relative;
      top: 198px;
    }
  }

  .sixth-section {
    img {
      position: relative;
      top: -211px;
    }

    .text-section {
      position: relative;
      top: 198px;
    }
  }
}
