@import "fonts";

* {
  box-sizing: border-box;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

@import "header";
@import "nav";
@import "home";
@import "about";
@import "projects";
@import "contact";
@import "skills";
