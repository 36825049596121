nav {
  width: 900px;
  background-color: rgb(0, 135, 255);
  margin: 0 auto;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
  }

  li {
    padding: 5px 0;
    font-size: 18px;

    a {
      text-decoration: none;
      color: white;
      font-family: $roboto;
    }

    a:hover {
      color: aqua;
    }
  }
}

@media screen and (max-width: 600px) {
  nav {
    width: 350px;
    margin: 0 auto;

    li {
      font-size: 12px;
      padding: 2px 0;
    }
  }
}
